import React, {Suspense, lazy} from 'react';
import './App.css';
import "./assets/App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Loading from "./scripts/components/elements/Loading";
import ErrorBoundary from "./scripts/components/elements/ErrorBoundary";

const Main = lazy(() => import('./scripts/components/Main'));
const LazyWebSiteRouter = lazy(() => import('./scripts/routers/WebSiteRouter'));
const LazyWebSiteAdminRouter = lazy(() => import('./scripts/routers/AdminRouter'));



export interface RouteElement {
    path: string;
    element: JSX.Element;
}


function App() {

    return (
        <ErrorBoundary>
    <BrowserRouter>


                <Routes>

                    <Route path="/" element={<Main />} />



                    <Route
                        path="/admin/*"
                        element={<Suspense fallback={<Loading />}>
                            <LazyWebSiteAdminRouter />
                        </Suspense>}
                    />

                    <Route
                        path="/*"
                        element={  <Suspense fallback={<Loading />}>
                            <LazyWebSiteRouter />
                        </Suspense>}
                    />
                </Routes>
    </BrowserRouter>
        </ErrorBoundary>

);
}

export default App;
